import React from 'react';
import * as PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import globalStyles from '../design-system/globalStyles';

const Layout = ({ className, children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={() => (
			<div className={className}>
				<Global
					styles={css`
						${globalStyles};
					`}
				/>
				{children}
			</div>
		)}
	/>
);

Layout.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

export default Layout;
