import { css } from '@emotion/core';

const breakpoints = {
	phone: 320,
	tablet: 768,
	desktop: 992,
	large: 1200,
	superLarge: 1920
};

const colors = {
	black: '#141414',
	white: '#ffffff',
	text: '#969696',
	primary: '#00FF00',
	logo: '#4B4B4B',
	buttons: '#282828',
	line: '#454545',
	icon: '#636363',
	error: '#ff0000',
	textPlaceholder: 'rgba(0, 0, 0, 0.5)',
	imagePlaceholder: 'rgba(0, 0, 0, 0.03)'
};

const dimensions = {
	contentWidthSmall: '260px',
	contentWidthLarge: '720px'
};

export default {
	breakpoints,
	colors,
	dimensions,
	typography: {
		body: css`
			font-size: 14px;
			font-family: 'Graphik Web', 'sans-serif';
			font-weight: 300;
			line-height: ${20 / 14};

			@media (min-width: ${breakpoints.tablet}px) {
				font-size: 14px;
				line-height: ${20 / 14};
			}
		`,
		bold: css`
			font-weight: 600;
		`,
		heading: {
			fontFamily: ['Graphik Web', 'sans-serif'],
			fontWeight: 600
		}
	},
	easings: {
		easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)'
	}
};
