import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from '../theme';

const BigHeading = ({ className, children }) => <h2 className={className}>{children}</h2>;

export const bigHeadingStyle = css`
	${theme.typography.body};
	margin-bottom: 10px;
	font-family: ${theme.typography.heading.fontFamily.join(',')};
	font-weight: ${theme.typography.heading.fontWeight};
`;

export default styled(BigHeading)`
	${bigHeadingStyle};
`;
