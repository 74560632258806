import { css } from '@emotion/core';
import GraphikMediumWebWoff from './Graphik-Medium-Web.woff';
import GraphikMediumWebWoff2 from './Graphik-Medium-Web.woff2';
import GraphikLightWebWoff from './Graphik-Light-Web.woff';
import GraphikLightWebWoff2 from './Graphik-Light-Web.woff2';

export default css`
	@font-face {
		font-family: 'Graphik Web';
		src: url(${GraphikMediumWebWoff2}) format('woff2'), url(${GraphikMediumWebWoff}) format('woff');
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
	}

	@font-face {
		font-family: 'Graphik Web';
		src: url(${GraphikLightWebWoff2}) format('woff2'), url(${GraphikLightWebWoff}) format('woff');
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
	}
`;
