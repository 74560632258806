import { css } from '@emotion/core';
import { normalize } from 'polished';
import theme from './theme';
import { bigHeadingStyle } from './typography/BigHeading';
import fonts from './fonts/fonts';

export default css`
	${normalize()};
	${fonts};

	html,
	body {
		${theme.typography.body};
		height: 100%;
		min-height: 500px;
		min-width: 320px;
		background-color: ${theme.colors.black};
		color: ${theme.colors.text};
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		touch-action: manipulation;
	}

	a {
		color: ${theme.colors.primary};
		text-decoration: none;
	}

	h2 {
		${bigHeadingStyle};
	}

	p {
		margin: 10px 0;
	}

	svg {
		fill: currentColor;
	}

	hr {
		border: none;
		border-top: 1px solid ${theme.colors.line};
	}

	ol {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style-position: inside;
		text-align: center;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			text-align: left;
			margin: 10px 0;
		}

		li {
			margin-bottom: 0.5em;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		text-align: left;
		list-style-type: none;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			margin: 10px 0;
		}

		li {
			margin-left: 19px;
			margin-bottom: 0.5em;
			position: relative;

			&::before {
				content: '';
				display: block;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				border: 1px solid ${theme.colors.text};
				position: absolute;
				top: 0.6em;
				left: -18px;
			}
		}
	}

	strong {
		${theme.typography.bold};
	}

	.small-viewport-only {
		@media (min-width: ${theme.breakpoints.tablet}px) {
			display: none;
		}
	}

	.large-viewport-only {
		@media (max-width: ${theme.breakpoints.tablet - 1}px) {
			display: none;
		}
	}
`;
